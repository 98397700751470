import React from 'react'
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { PageProps } from 'gatsby'
import { IGatsbyImageData } from "gatsby-plugin-image"
import ContentPage from '../components/ContentPage'
import { useIntl } from "gatsby-plugin-intl"

type LeistungenQueryProps = PageQuery<{
  hero: HeroImageData<IGatsbyImageData>;
  title: string;
  body: MarkdownField;
  sections: {
    title: string;
    image?: IGatsbyImageData;
    video?: string;
    text: MarkdownField;
  }[];
}>

const LeistungenPage = ({ data }: PageProps<LeistungenQueryProps>) => {
  const { locale } = useIntl()
  const { markdownRemark } = data
  const frontmatter = markdownRemark.frontmatter[locale]!

  const transformedSections = frontmatter.sections.map(section => ({
    ...section,
    content: <div dangerouslySetInnerHTML={{__html: section.text.html}} />
  }))

  return (
    <Layout
      seo={{title: frontmatter.title, description: frontmatter.body.html}}
      hero={frontmatter.hero}
      introCard={{
        title: frontmatter.title,
        body: frontmatter.body.html
      }}
    >
      <ContentPage sections={transformedSections} />
    </Layout>
  )
}

export const pageQuery = graphql`
  fragment LeistungenFragment on FrontmatterLocalized {
    hero {
      image {
        desktopHero: childImageSharp {
          gatsbyImageData(
            width: 1920
            aspectRatio: 2.5
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
          )
        }
        mobileHero: childImageSharp {
          gatsbyImageData(
            width: 760
            aspectRatio: 1.5
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
          )
        }
      }
    }
    title
    body {
      html
    }
    sections {
      title
      image {
        childImageSharp {
          gatsbyImageData(
            width: 900
            placeholder: BLURRED
          )
        }
      }
      video
      text {
        html
      }
    }
  }

  query LeistungenPage {
    markdownRemark(fileAbsolutePath: {regex: "/pages\/leistungen.md/"}) {
      frontmatter {
        de {
          ...LeistungenFragment
        }
        en {
          ...LeistungenFragment
        }
      }
    }
  }
`

export default LeistungenPage
